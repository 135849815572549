import Slider1 from "../assets/img/slider-1.png"
import Slider2 from "../assets/img/slider-2.png"
import Slider3 from "../assets/img/slider-3.png"
export const SliderData = [
    {
      name: "The Best Quality Products Guaranteed!",
      description: "Dramatically facilitate effective total linkage for go forward processes",
      img: Slider1,
      path:"biscuits--cakes"
    },
    {
        name: "Best Different Type of Grocery Store",
        description: "Quickly aggregate empowered networks after emerging products",
        img: Slider2,
        path:"fish--meat"
      },
      {
        name: "Quality Freshness Guaranteed!",
        description: "Intrinsicly fashion performance based products rather than accurate benefits",
          img: Slider3,
          path:"fresh-vegetable"
      },
  ];